/**
 * @generated SignedSource<<d4b8c3ef542236a6e8488f67b91def42>>
 * @relayHash bf5bced307eb84a72b3c4811067a79ae
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID app-buyer-finding/476.0.0-2024-08-20T10:44:53.969Z/SharedItemTileSubscription

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AuctionItemUpdateSubscriptionInput = {
  itemId: string;
};
export type SharedItemTileSubscription$variables = {
  input: AuctionItemUpdateSubscriptionInput;
  isTrade?: boolean | null;
  priceBookName?: string | null;
  showSeller?: boolean | null;
};
export type SharedItemTileSubscription$data = {
  readonly auctionItemUpdate: {
    readonly item: {
      readonly " $fragmentSpreads": FragmentRefs<"SharedItemTile_item">;
    } | null;
  } | null;
};
export type SharedItemTileSubscription = {
  response: SharedItemTileSubscription$data;
  variables: SharedItemTileSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isTrade"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "priceBookName"
},
v3 = {
  "defaultValue": false,
  "kind": "LocalArgument",
  "name": "showSeller"
},
v4 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v5 = {
  "kind": "Variable",
  "name": "isTrade",
  "variableName": "isTrade"
},
v6 = {
  "kind": "Variable",
  "name": "priceBookName",
  "variableName": "priceBookName"
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "serviceId",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "LinkData",
  "kind": "LinkedField",
  "name": "linkData",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "path",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isLinkable",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v12 = {
  "kind": "Literal",
  "name": "page",
  "value": "searchAndBrowse"
},
v13 = [
  (v12/*: any*/)
],
v14 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "amount",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "currency",
    "storageKey": null
  }
],
v15 = {
  "alias": null,
  "args": null,
  "concreteType": "ConvertedAmountList",
  "kind": "LinkedField",
  "name": "convertedAmountList",
  "plural": true,
  "selections": (v14/*: any*/),
  "storageKey": null
},
v16 = [
  {
    "kind": "Literal",
    "name": "formatType",
    "value": "MOBILE"
  }
],
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v18 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "unit",
    "storageKey": null
  },
  (v17/*: any*/)
],
v19 = {
  "kind": "Literal",
  "name": "addLabel",
  "value": false
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "smallPath",
  "storageKey": null
},
v22 = [
  {
    "kind": "Literal",
    "name": "limit",
    "value": 1
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SharedItemTileSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "AuctionItemUpdateSubscriptionPayload",
        "kind": "LinkedField",
        "name": "auctionItemUpdate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Item",
            "kind": "LinkedField",
            "name": "item",
            "plural": false,
            "selections": [
              {
                "args": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "kind": "Variable",
                    "name": "showSeller",
                    "variableName": "showSeller"
                  }
                ],
                "kind": "FragmentSpread",
                "name": "SharedItemTile_item"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Subscriptions",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "SharedItemTileSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "AuctionItemUpdateSubscriptionPayload",
        "kind": "LinkedField",
        "name": "auctionItemUpdate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Item",
            "kind": "LinkedField",
            "name": "item",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "pdpMetaItems",
                "kind": "LinkedField",
                "name": "pdpMeta",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "topQuery",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "localizedPdpUrl",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ItemClassification",
                "kind": "LinkedField",
                "name": "classification",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "creationDate",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ItemCreator",
                "kind": "LinkedField",
                "name": "creators",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "attribution",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Creator",
                    "kind": "LinkedField",
                    "name": "creator",
                    "plural": false,
                    "selections": [
                      (v9/*: any*/),
                      (v10/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v10/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isArt",
                "storageKey": null
              },
              {
                "condition": "showSeller",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Seller",
                    "kind": "LinkedField",
                    "name": "seller",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SellerProfile",
                        "kind": "LinkedField",
                        "name": "sellerProfile",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "company",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v10/*: any*/)
                    ],
                    "storageKey": null
                  }
                ]
              },
              (v11/*: any*/),
              {
                "alias": null,
                "args": (v13/*: any*/),
                "concreteType": "AuctionSolution",
                "kind": "LinkedField",
                "name": "relevantAuctionSolution",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasReserve",
                    "storageKey": null
                  },
                  (v10/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "reserveMet",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AuctionBidsConnection",
                    "kind": "LinkedField",
                    "name": "bids",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "totalResults",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "relevantAuctionSolution(page:\"searchAndBrowse\")"
              },
              {
                "alias": null,
                "args": (v13/*: any*/),
                "concreteType": "AuctionLot",
                "kind": "LinkedField",
                "name": "relevantAuctionLot",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                  },
                  (v10/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "startDate",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endDate",
                    "storageKey": null
                  }
                ],
                "storageKey": "relevantAuctionLot(page:\"searchAndBrowse\")"
              },
              {
                "alias": null,
                "args": [
                  (v5/*: any*/),
                  (v12/*: any*/),
                  (v6/*: any*/)
                ],
                "concreteType": "ItemDisplayPriceType",
                "kind": "LinkedField",
                "name": "displayPrice",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "textType",
                    "storageKey": null
                  },
                  (v15/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "quantityDisplay",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "percentageOff",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "amountType",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "showPriceLabel",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "showPriceVariability",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isAuctionEnabled",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "vertical",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ShipmentQuote",
                "kind": "LinkedField",
                "name": "shipmentQuotes",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isComplimentary",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isCalculated",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "FinancialAmount",
                    "kind": "LinkedField",
                    "name": "totalAmount",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ConvertedAmounts",
                        "kind": "LinkedField",
                        "name": "convertedAmounts",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "USD",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ShipmentServiceMethod",
                    "kind": "LinkedField",
                    "name": "serviceMethod",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Region",
                        "kind": "LinkedField",
                        "name": "region",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "regionName",
                            "storageKey": null
                          },
                          (v10/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v10/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v10/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isStorefrontOnly",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isMultiSku",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isRingItem",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "VariableAttribute",
                "kind": "LinkedField",
                "name": "variableAttributes",
                "plural": true,
                "selections": [
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "VariableAttributeSortType",
                    "kind": "LinkedField",
                    "name": "variableAttributeData",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SwatchSort",
                        "kind": "LinkedField",
                        "name": "swatchSort",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Swatch",
                            "kind": "LinkedField",
                            "name": "swatch",
                            "plural": false,
                            "selections": [
                              (v7/*: any*/),
                              (v9/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "imageUrl",
                                "storageKey": null
                              },
                              (v10/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v10/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": "originalDisplayPrice",
                "args": (v13/*: any*/),
                "concreteType": "ItemDisplayPriceType",
                "kind": "LinkedField",
                "name": "displayPrice",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ConvertedAmountList",
                    "kind": "LinkedField",
                    "name": "originalConvertedAmountList",
                    "plural": true,
                    "selections": (v14/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": "displayPrice(page:\"searchAndBrowse\")"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ItemPricing",
                "kind": "LinkedField",
                "name": "pricing",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "FinancialAmount",
                    "kind": "LinkedField",
                    "name": "retailPrice",
                    "plural": false,
                    "selections": [
                      (v15/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "QuickViewDisplayType",
                "kind": "LinkedField",
                "name": "quickViewDisplay",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  {
                    "alias": "mobileTitle",
                    "args": (v16/*: any*/),
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": "title(formatType:\"MOBILE\")"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "QuickViewParagraph",
                    "kind": "LinkedField",
                    "name": "paragraphs",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "key",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "text",
                        "storageKey": null
                      },
                      {
                        "alias": "mobileText",
                        "args": (v16/*: any*/),
                        "kind": "ScalarField",
                        "name": "text",
                        "storageKey": "text(formatType:\"MOBILE\")"
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "QuickViewCreator",
                    "kind": "LinkedField",
                    "name": "creators",
                    "plural": true,
                    "selections": [
                      (v9/*: any*/),
                      (v11/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "QuickViewCategorySegments",
                    "kind": "LinkedField",
                    "name": "paragraphAttributeNames",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "period",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "origin",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "style",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "periodPrefix",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ItemMeasurement",
                "kind": "LinkedField",
                "name": "measurement",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "addLabel",
                        "value": true
                      },
                      {
                        "kind": "Literal",
                        "name": "fields",
                        "value": [
                          "height",
                          "width",
                          "depth",
                          "diameter",
                          "length"
                        ]
                      },
                      {
                        "kind": "Literal",
                        "name": "separator",
                        "value": " "
                      }
                    ],
                    "concreteType": "ItemDisplayMeasurementType",
                    "kind": "LinkedField",
                    "name": "display",
                    "plural": true,
                    "selections": (v18/*: any*/),
                    "storageKey": "display(addLabel:true,fields:[\"height\",\"width\",\"depth\",\"diameter\",\"length\"],separator:\" \")"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ItemSize",
                    "kind": "LinkedField",
                    "name": "size",
                    "plural": false,
                    "selections": [
                      (v17/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "unit",
                        "value": "IN"
                      }
                    ],
                    "concreteType": "ItemConvertedMeasurementsType",
                    "kind": "LinkedField",
                    "name": "convertedMeasurements",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "width",
                        "storageKey": null
                      }
                    ],
                    "storageKey": "convertedMeasurements(unit:\"IN\")"
                  },
                  {
                    "alias": "widthDimensions",
                    "args": [
                      (v19/*: any*/),
                      {
                        "kind": "Literal",
                        "name": "fields",
                        "value": [
                          "width"
                        ]
                      }
                    ],
                    "concreteType": "ItemDisplayMeasurementType",
                    "kind": "LinkedField",
                    "name": "display",
                    "plural": true,
                    "selections": (v18/*: any*/),
                    "storageKey": "display(addLabel:false,fields:[\"width\"])"
                  },
                  {
                    "alias": "heightDimensions",
                    "args": [
                      (v19/*: any*/),
                      {
                        "kind": "Literal",
                        "name": "fields",
                        "value": [
                          "height"
                        ]
                      }
                    ],
                    "concreteType": "ItemDisplayMeasurementType",
                    "kind": "LinkedField",
                    "name": "display",
                    "plural": true,
                    "selections": (v18/*: any*/),
                    "storageKey": "display(addLabel:false,fields:[\"height\"])"
                  }
                ],
                "storageKey": null
              },
              {
                "alias": "isInEurope",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "regionName",
                    "value": "Europe"
                  }
                ],
                "kind": "ScalarField",
                "name": "isInRegion",
                "storageKey": "isInRegion(regionName:\"Europe\")"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Address",
                "kind": "LinkedField",
                "name": "address",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "displayCountry",
                    "storageKey": null
                  },
                  (v10/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": "carouselPhotos",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "limit",
                    "value": 2
                  }
                ],
                "concreteType": "ItemPhoto",
                "kind": "LinkedField",
                "name": "photos",
                "plural": true,
                "selections": [
                  (v20/*: any*/),
                  (v10/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "placeholder",
                    "storageKey": null
                  },
                  (v21/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "masterOrZoomPath",
                    "storageKey": null
                  }
                ],
                "storageKey": "photos(limit:2)"
              },
              {
                "alias": null,
                "args": (v22/*: any*/),
                "concreteType": "viewInRoomPhotos",
                "kind": "LinkedField",
                "name": "viewInRoomPhotos",
                "plural": true,
                "selections": [
                  (v20/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "src",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "viewInRoomPhotosCenterPosition",
                    "kind": "LinkedField",
                    "name": "centerPosition",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "top",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "left",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "viewInRoomPhotosComparatorWidth",
                    "kind": "LinkedField",
                    "name": "comparatorWidth",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "pixel",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "inches",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "viewInRoomPhotos(limit:1)"
              },
              {
                "alias": "productPhotos",
                "args": (v22/*: any*/),
                "concreteType": "ItemPhoto",
                "kind": "LinkedField",
                "name": "photos",
                "plural": true,
                "selections": [
                  (v21/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ItemPhotoVersion",
                    "kind": "LinkedField",
                    "name": "versions",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "webPath",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v10/*: any*/)
                ],
                "storageKey": "photos(limit:1)"
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isNewListing",
                "storageKey": null
              },
              (v10/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "app-buyer-finding/476.0.0-2024-08-20T10:44:53.969Z/SharedItemTileSubscription",
    "metadata": {},
    "name": "SharedItemTileSubscription",
    "operationKind": "subscription",
    "text": null
  }
};
})();

(node as any).hash = "2859174e5bf1150c8a9c5ca7eb253272";

export default node;
