/**
 * @generated SignedSource<<96719876e943be8f48302814550b4465>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SharedItemTile_itemSearch$data = {
  readonly pageType: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"ItemTileCarousel_itemSearch">;
  readonly " $fragmentType": "SharedItemTile_itemSearch";
};
export type SharedItemTile_itemSearch$key = {
  readonly " $data"?: SharedItemTile_itemSearch$data;
  readonly " $fragmentSpreads": FragmentRefs<"SharedItemTile_itemSearch">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SharedItemTile_itemSearch",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ItemTileCarousel_itemSearch"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "pageType",
      "storageKey": null
    }
  ],
  "type": "ItemSearchQueryConnection",
  "abstractKey": null
};

(node as any).hash = "51d08c471173bb563883035f2f091994";

export default node;
