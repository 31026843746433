import { FC } from 'react';
import classnames from 'classnames';

import styles from './SbRespPlaceholderTile.scss';

type Props = {
    fiveItems?: boolean;
};

export const SbRespPlaceholderTile: FC<Props> = ({ fiveItems }) => (
    <div className={classnames(styles.wrapper, { [styles.item5InRow]: fiveItems })}>
        <div className={styles.tile}>
            <div className={styles.image} />
            <div className={styles.title} />
            <div className={styles.subtitle} />
            <div className={styles.price} />
        </div>
    </div>
);
